<template>
    <div class="grid custom-scroll gap-y-2.5 mt-2.5 px-2.5 w-full max-mb:max-h-full max-md:pb-24 max-h-screen-70">
        <router-link
            class="flex items-center gap-x-5 px-1.25 py-1.75 block-bg-hover rounded-lg transition-colors cursor-pointer select-none"
            v-for="item in items"
            :key="item.slug"
            @click="handleClick(item)"
            :to="goToPage(item)"
        >
            <div
                class="h-5 w-21.75 flex justify-center items-center rounded-custom text-xxxs text-white"
                :style="styles(item)"
            >
                {{ name(item) }}
            </div>

            <div class="max-mb:max-w-50 max-w-100 text-sm text-gray-370 dark:text-white truncate">
                {{ item.fieldName }}
            </div>

            <div class="flex items-center gap-x-1.25 ml-auto text-gray-200">
                <div class="text-xxxs">
                    Open
                </div>

                <ArrowTopLeftIcon />
            </div>
        </router-link>
    </div>
</template>

<script setup>
import ArrowTopLeftIcon from '@/components/icons/arrow/ArrowTopLeftIcon.vue'

import { createQueryForGlobalSearch } from '@/helpers/leads-helper'

import { useAuthorizationStore } from '@/store/authorizationStore'
import { useLeadsStore } from '@/store/call-tracking/leads/leadsStore'
import { useBlockedNumbersStore } from '@/store/call-tracking/blockedNumbersStore'
import { useDetailNumberPool } from '@/store/call-tracking/numberPools/detailNumberPoolStore'

import { GLOBAL_SEARCH_TYPES } from '@/constants/globalSearchTypes'

defineProps({
    items: {
        type: Array,
        default: () => [],
    },
})

const emits = defineEmits(['closeGlobalSearch'])

const authorizationStore = useAuthorizationStore()
const leadsStore = useLeadsStore()
const blockedNumbersStore = useBlockedNumbersStore()
const detailNumberPool = useDetailNumberPool()

function name({ entity }) {
    return GLOBAL_SEARCH_TYPES[entity].name
}

function styles({ entity }) {
    return {
        background: GLOBAL_SEARCH_TYPES[entity].style,
    }
}

function handleClick(item) {
    const { entity, fieldName } = item
    if (GLOBAL_SEARCH_TYPES[entity].name === GLOBAL_SEARCH_TYPES.leads.name) {
        leadsStore.goToAfterGlobalSearch(fieldName)
        emits('closeGlobalSearch')
        return
    }

    if (GLOBAL_SEARCH_TYPES[entity].name === GLOBAL_SEARCH_TYPES.blocked_numbers.name) {
        blockedNumbersStore.onSearch(fieldName)
        emits('closeGlobalSearch')
        return
    }

    if (GLOBAL_SEARCH_TYPES[entity].name === GLOBAL_SEARCH_TYPES.number_in_pool.name) {
        detailNumberPool.changeSearch(fieldName)
        emits('closeGlobalSearch')
        return
    }

    emits('closeGlobalSearch')
}

function goToPage({ entity, slug, created_at }) {
    const type = GLOBAL_SEARCH_TYPES[entity]

    return {
        name: type.route,
        params: {
            workspace: authorizationStore.workspace.hasOwnProperty('slug') ? authorizationStore.workspace.slug : null,
            slug,
        },
        query: getQuery(entity, created_at),
    }
}

function getQuery(entity, created_at) {
    if (GLOBAL_SEARCH_TYPES[entity].name === GLOBAL_SEARCH_TYPES.leads.name) {
        return createQueryForGlobalSearch(created_at)
    }

    return undefined
}
</script>
