function checkPermission(permissions, permissionName) {
    return permissions ? !!permissions[permissionName] : false
}

function checkSomePermission(permissions, permissionNames = []) {
    if (!permissions) {
        return false
    }

    return permissionNames.some((name) => permissions[name])
}

export {
    checkPermission, checkSomePermission,
}
